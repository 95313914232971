<template>
  <div class="SPEC">
    <div class="stand">
      <ul>
        <li class="attr"
            v-for="(item,index) in standData"
            :key="index">
          <span @click="remove(index)">删除:</span>
          <el-input class="title"
                    v-model="item.title"
                    placeholder="请输入属性">:</el-input>
          <div v-if="item.attrs.length"
               class="puttAll">
            <div class="putt"
                 v-for="(subitem,i) in item.attrs"
                 :key="i">
              <el-input v-model="subitem.attr"
                        placeholder="请输入值"></el-input>
              <div class="close"
                   @click="closeAtrr(index,i)">×</div>
            </div>
          </div>

          <div class="append"
               @click="addAtrr(index)">+</div>
        </li>
      </ul>

      <!-- 价格部分 -->
      <el-form ref="form"
               :model="priceForm"
               v-if="formFlag">
        <el-form-item v-for="item in priceFormLabel"
                      :key="item.id"
                      style="display:inline-block;">
          <el-input v-model.trim="priceForm[item.model]"
                    :placeholder="'请输入' + item.label"
                    :style="'width:240px' ">
            <template slot="append"
                      v-if="item.tip">{{item.tip}}</template>
          </el-input>
        </el-form-item>
      </el-form>

      <!-- 按钮部分 -->
      <div class="add">
        <el-button type="primary"
                   @click="addStand">添加规格</el-button>
        <el-button type="primary"
                   @click="getTable">生成规格列表</el-button>
        <el-button type="primary"
                   @click="read">读取规格列表</el-button>
      </div>

    </div>

    <div class="table">
      <el-table v-if="isTable"
                :data="specTableData"
                :row-key="'id'"
                border
                style="width: 100%">
        <el-table-column prop="name"
                         label="属性"
                         width="180">
        </el-table-column>
        <template v-for="item in specTableLabel">

          <el-table-column :label="item.label"
                           :key="item.id"
                           :prop="item.prop"
                           v-if="!item.type">
            <template slot-scope="scope">
              <el-input v-model="scope.row[item.prop]"
                        placeholder=""
                        style="width:80px"></el-input>
            </template>
          </el-table-column>
        </template>

        <!-- <el-table-column prop="name"
                         label="价格1"
                         width="180">
        </el-table-column>
        <el-table-column prop="address"
                         label="价格2">
        </el-table-column> -->
      </el-table>
    </div>

  </div>
</template>
<style lang="scss" scoped>
.SPEC {
  li {
    list-style: none;
  }
  .table,
  .stand {
    padding: 40px;
  }
  .table {
    height: 500px;
  }
  .add {
    margin-top: 20px;
  }
  .attr {
    margin-bottom: 10px;
  }
  .el-input {
    width: auto;
  }
  .putt {
    display: inline-block;
    position: relative;
    margin-left: 36px;
    margin-top: 10px;
  }
  .append {
    width: 40px;
    height: 40px;
    background-color: aqua;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 28px;
    cursor: pointer;
    vertical-align: middle;
  }
  .title {
    background-color: bisque;
    margin-right: 10px;
  }
  .close {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: burlywood;
    border-radius: 50%;
    line-height: 15px;
    text-align: center;
    right: -5px;
    top: -5px;
  }
  .shop_all {
    display: flex;
    margin-top: 20px;
    .shop_title {
      display: inline-block;
      width: 12%;
      text-align: center;
    }
    .shop_value {
      width: 90%;
      border: 1px solid #dcdfe6;
      // display: inline-block;
      .spec_name {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        background-color: #f8f7f7;
        span {
          color: #409eff;
          cursor: pointer;
          float: right;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
      .spec_value {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        background-color: white;
        div {
          display: inline-block;
        }
        a {
          color: #409eff;
          font-size: 12px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .spec_btn {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        // width: 800px;
        background-color: #f8f7f7;
      }
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      specTableData: [],
      input: '',
      isTable: false,
      standData: [],
      list: [],
      group: [],
      formFlag: false,
      priceForm: {
        original_price: '',
        cost_price: '',
        sales_price: '',
        vip_price: '',
        freight_price: '',
        stock_count: '',
        integral: '',
        sales_count: '',
        note: ''
      },
      priceFormLabel: [
        {
          model: 'original_price',
          label: '原价',
          tip: '￥'
        },
        {
          model: 'cost_price',
          label: '成本价',
          tip: '￥'
        },
        {
          model: 'sales_price',
          label: '销售价',
          tip: '￥'
        },
        {
          model: 'vip_price',
          label: '会员价',
          tip: '￥'
        },
        {
          model: 'freight_price',
          label: '运费',
          tip: '￥'
        },
        {
          model: 'stock_count',
          label: '库存',
        },
        {
          model: 'integral',
          label: '可使用的积分',
        },
        {
          model: 'sales_count',
          label: '销量',
        },
        {
          model: 'note',
          label: '备注',
        },
      ],
      specTableLabel: [
        {
          prop: 'no',
          label: '规格编码',
        },
        {
          prop: 'original_price',
          label: '原价',
        },
        {
          prop: 'cost_price',
          label: '成本价'
        },
        {
          prop: 'sales_price',
          label: '销售价'
        },
        {
          prop: 'vip_price',
          label: '会员价'
        },
        {
          prop: 'stock_count',
          label: '库存'
        },
        {
          prop: 'freight_price',
          label: '运费'
        },
        {
          prop: 'image',
          label: '图片'
        },
        {
          prop: 'is_coupon',
          label: '可否用券'
        },
        {
          prop: 'integral',
          label: '可使用的积分'
        },
        {
          prop: 'sales_count',
          label: '销量'
        },
        {
          prop: 'note',
          label: '备注'
        },
      ],
    }
  },
  created () {

  },
  methods: {
    // 添加规格行
    addStand (i) {
      this.formFlag = true
      //  限制规格种类不超过4种
      if (this.standData.length > 3) {
        this.$message('不能超过四行')
      } else {
        this.standData.push({ title: '', attrs: [] })
      }
    },
    // 添加规格表格
    getTable () {
      this.isTable = true
      this.specTableData = []
      this.group = []
      this.list = []
      //   console.log(this.standData);
      const num = this.standData.length
      this.standData.forEach(item => {
        this.list.push(item.attrs)
      });
      //   console.log(this.list);
      var arr = []
      var that = this
      function func (skuarr = [], i) {
        for (let j = 0; j < that.list[i].length; j++) {
          if (i < that.list.length - 1) {
            skuarr[i] = that.list[i][j].attr
            func(skuarr, i + 1)
          } else {
            arr.push([...skuarr, that.list[i][j].attr])
          }
        }
        return arr
      }
      let newList = func([], 0)
      let b
      newList.forEach(item => {
        b = ''
        for (let i = 0; i < num; i++) {
          let a = this.standData[i].title
          a = a + ':' + item[i]
          b = b + a + ';'
        }
        this.group.push(b)
      })
      let table = []
      for (let j = 0; j < this.group.length; j++) {
        table.push({
          name: this.group[j],
          original_price: this.priceForm.original_price,
          cost_price: this.priceForm.cost_price,
          sales_price: this.priceForm.sales_price,
          vip_price: this.priceForm.vip_price,
          freight_price: this.priceForm.freight_price,
          stock_count: this.priceForm.stock_count,
          integral: this.priceForm.integral,
          sales_count: this.priceForm.sales_count,
          note: this.priceForm.note,
        })
      }
      this.specTableData = table
    },

    // 删除规格行
    remove (i) {
      this.standData.splice(i, 1)
    },
    // 添加属性值
    addAtrr (i) {
      //  限制属性值不超过5个
      if (this.standData[i].attrs.length > 10) {
        this.$message('不能超过10个')
      } else {　　　　　　　　// 存的时候是存的对象
        this.standData[i].attrs.push({ attr: '' })
      }
    },
    // 删除属性值
    closeAtrr (a, b) {
      //   console.log(a, b);
      this.standData[a].attrs.splice(b, 1)
    },
    // 读取规格属性数组
    read () {　　　　　　// 如果后台返回的数据是这样的
      // const arr = [
      //   // '颜色:红色;尺码:M;品质:好;',
      //   // '颜色:红色;尺码:S;品质:好;',
      //   // '颜色:白色;尺码:M;品质:好;',
      //   // '颜色:白色;尺码:S;品质:好;'
      // ]
      // const a = arr[0].split(';')
      // const num = a.length - 1
      // let ss = []
      // for (let tt = 0; tt < num; tt++) {
      //   ss.push([])
      // }

      // arr.forEach(item => {
      //   for (let tt = 0; tt < num; tt++) {
      //     ss[tt].push(item.split(';')[tt].split(':')[1])
      //   }
      // })
      // ss = ss.map(item => {
      //   return Array.from(new Set(item))
      // })
      // for (let s = 0; s < ss.length; s++) {
      //   for (let t = 0; t < ss[s].length; t++) {
      //     ss[s][t] = { attr: ss[s][t] }
      //   }
      // }
      // for (let i = 0; i < num; i++) {
      //   this.standData.push({ 'title': a[i].split(':')[0], attrs: ss[i] })
      // }
      console.log(this.specTableData);

    }


  }
}
  </script>